import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions-️"
    }}>{`Frequently Asked Questions 🙋‍♀️`}</h1>
    <p>{`Some of our most common questions`}</p>
    <hr></hr>
    <p><strong parentName="p">{`What does the Canyou integration sync with Xero?`}</strong></p>
    <p>{`Canyou syncs Employees into your Xero organisation. Canyou will sync an employees tax information, superannuation information and
bank account details provided they are entered into the Canyou user profile.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`When and how does data sync occur?`}</strong></p>
    <p>{`Canyou syncs employees into your Xero organisation manually. Synchronising employees must be done on an employee by employee basis.
Follow our guide on how to sync your employees`}</p>
    <hr></hr>
    <p><strong parentName="p">{`What doesn't it do?`}</strong></p>
    <p>{`Canyou does not synchronise invoices or other common Xero functions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      